import gql from 'graphql-tag';

/** ---------- PROFILE ------------ */

export const me = gql`
  query me {
    me {
      id
      email
      first_name
      last_name
      roles {
        id
        name
        permissions {
          id
          import
          create
          read
          update
          delete
        }
      }
      api_token
      user_type
      manufacturers {
        manufacturer{
          id
          name
          alt_name
        }
      }

      sellers {
        seller{
          id
          name
          short_name
          account_type
        }
      }
    }
  }
`;

/** ---------- USERS ------------ */

export const getUsers = gql`
  query searchUsers($first: Int!, $page: Int!, $input: String, $order: String!) {
    searchUsers(
      first: $first
      page: $page
      input: { search: $input }
      orderBy: { field: $order, order: DESC }
    ) {
      paginatorInfo {
        total
        hasMorePages
        count
        lastPage
        currentPage
        lastItem
        perPage
      }
      data {
        id
        first_name
        last_name
        email
        user_type
        roles {
          id
        }
        manufacturers {
          manufacturer{
            id
          }
        }
        sellers {
          seller{
            id
          }
        }
        created_at
        last_login
        status
      }
    }
  }
`;

/** ---------- ROLES ------------ */

export const getRolesList = gql`
  query roles($order: String!) {
    roles(orderBy: { field: $order, order: ASC }) {
      id
      name
      user_type
      status
      permissions {
        id
        import
        create
        read
        update
        delete
      }
    }
  }
`;

/** ---------- MANUFACTURERS ------------ */

export const getManufacturersList = gql`
  query manufacturers($order: String!) {
    manufacturers(orderBy: { field: $order, order: ASC }) {
      id
      name
      alt_name
      type
      service_type
    }
  }
`;

export const getManufacturers = gql`
  query searchManufacturers(
    $first: Int!
    $page: Int!
    $input: String
    $order: String!
    $filter: String
  ) {
    searchManufacturers(
      first: $first
      page: $page
      input: { search: $input, filters: $filter }
      orderBy: { field: $order, order: DESC }
    ) {
      paginatorInfo {
        total
        hasMorePages
        count
        lastPage
        currentPage
        lastItem
        perPage
      }
      data {
        id
        name
        slug
        type
        service_type
        commission_type
        commission_percentage
        status
        advertising_status
        alt_name
      }
    }
  }
`;

export const getManufacturer = gql`
  query manufacturer($slug: String!) {
    manufacturer(slug: $slug) {
      id
      name
      status
      advertising_status
      commission_type
      commission_percentage
      margin_percentage
      slug
      alt_name
      type
      service_type
      portfolios{
        id
        name
        status
      }
      manufacturerAdvertisingInforamtions{
        id
        advertising_type
        from_ad_spend
        to_ad_spend
        commission_type
        value
      }
      advertising_channel_id
      advertisingChannel{
        id
        channel
      }
      brands {
        id
        name
        status
        slug
      }
      salesTargets{
        id
        period_date
        revenue
      }
    }
  }
`;

/** ---------- PRODUCTS ------------ */

export const searchProducts = gql`
query searchProductsList(
  $keyword: String
) {
  searchProductsList(
    keyword: $keyword
  ) {
    
    slug
    
  }
}
`

export const getProducts = gql`
  query searchProducts(
    $first: Int!
    $page: Int!
    $input: String
    $order: String!
    $filter: String
  ) {
    searchProducts(
      first: $first
      page: $page
      input: { search: $input, filters: $filter }
      orderBy: { field: $order, order: DESC }
    ) {
      paginatorInfo {
        total
        hasMorePages
        count
        lastPage
        currentPage
        lastItem
        perPage
      }
      data {
        product_parent_id
        manufacturer {
          name
        }
        brand {
          name
        }
        status
        type {
          name
        }
        tags{
          id
          tag_id
          portfolio{
            id
            name
          }
        }
        product_name
        product_details
        product_contents
        product_type_id
        seasonality
        packagingUnit {
          name
        }
        gtin
        pzn
        slug
        attributes
        attribute_values
        manufacturer_product_id
        product_category_pharmacy
        product_category_hazmat
        product_category_electronics
        product_category_food
        hazmatCategory{
          name
        }
        foodCategory{
          name
        }
        electronicsCategory{
          name
        }
        pharmacyCategory{
          name
        }
        base_content_volume
        base_unit
        is_waren_post
        productLogistic {
          article_height
          article_width
          article_depth
          article_weight
          article_unit_volume
          article_packaging_type_id
          logistics_ve_unit_volume
          articlePackagingType{
            name
          }
        }
        productMarketplaces {
          id
          product_child_id
          marketplace_id
          marketplace_product_id
          marketplace_id_type
          status
          fee_category_id
          seller_id
          marketplace{
            id
            name
          }
          skuFullFilmentVendors {
            fulfillment_vendor_id
            fulfillment_vendor_number_id
            inventory_id
            fulfillment{
              id
              name
            }
          }
          feeCategory{
            name
          }
          seller{
            name
          }
          hasSale{
            id
            pid
            sku
            has_sales
          }
          error
        }
        productPrices {
          id
          marketplace_id
          marketplace_product_id
          tax_rate
          provision
          currency_id
          price_sell_gross_rrp_ct
          price_sell_net_rrp_ct
          price_sell_gross_min_ct
          price_sell_net_min_ct
          price_sell_gross_max_ct
          price_sell_net_max_ct
          price_buy_net_actual_ct
          tax_code
          status
          manufacturer_margin
          additional_costs
          rrp_value
          unit_economics_buy_price
          marketplace{
            id
            name
          }
        }
        customs {
          id
          country
          product_tax_number
          product_country_of_origin
        }
        fbmsize{
          id
          size
          min
          max
          uid
        }
        biozid
        inhaltsstoffe
        allergene
        lot_number_required
        expiration_date
        marketplace_errors
        safety_data_sheet
        gs1_certificate
        live_images
        warnings_and_instructions
        video_material
      }
    }
  }
`;

export const getProduct = gql`
  query product($slug: String!) {
    product(slug: $slug) {
      product_parent_id
      manufacturer {
        id
        name
        slug
        status
        margin_percentage
        commission_type
        commission_percentage
      }
      brand {
        id
        name
      }
      status
      type {
        id
        name
      }
      tags{
        id
        tag_id
        portfolio{
          id
          name
        }
      }
      product_name
      product_details
      product_contents
      product_type_id
      seasonality
      packagingUnit {
        id
        name
      }
      gtin
      pzn
      slug
      attributes
      attribute_values
      manufacturer_product_id
      product_category_pharmacy
      product_category_hazmat
      product_category_electronics
      product_category_food
      product_category_amazon
      product_category_ebay
      product_category_kaufland
      product_category_otto
      base_content_volume
      base_unit
      is_waren_post
      productLogistic {
        article_height
        article_width
        article_depth
        article_weight
        article_unit_volume
        article_packaging_type_id
        logistical_unit_weight
        logistical_unit_width
        logistical_unit_height
        logistical_unit_depth
        logistical_unit_volume
        packaging_unit_logistical
        packaging_unit_per_eur_pallet
        logistics_ve_height
        logistics_ve_width
        logistics_ve_depth
        logistics_ve_weight
        logistics_ve_unit_volume
      }
      productMarketplaces {
        id
        product_child_id
        marketplace_id
        marketplace_product_id
        marketplace_id_type
        status
        fee_category_id
        marketplace{
          id
          name
        }
        skuFullFilmentVendors {
          fulfillment_vendor_id
          fulfillment_vendor_number_id
          inventory_id
          fulfillment{
            id
            name
          }
        }
        seller_id
        hasSale{
          id
          pid
          sku
          has_sales
        }
        error
        plenty_sync
        is_read_only
      }
      productPrices {
        id
        marketplace_id
        marketplace_product_id
        tax_rate
        provision
        currency_id
        price_sell_gross_rrp_ct
        price_sell_net_rrp_ct
        price_sell_gross_min_ct
        price_sell_net_min_ct
        price_sell_gross_max_ct
        price_sell_net_max_ct
        price_buy_net_actual_ct
        tax_code
        status
        manufacturer_margin
        additional_costs
        rrp_value
        unit_economics_buy_price
        marketplace{
          id
          name
          gross_tax_rate
          currency_id
          tax_rate
        }
      }
      customs {
        id
        country
        product_tax_number
        product_country_of_origin
      }
      bundleItems{
        id
        bundled_product_parent_id
        product_qty
        product{
          product_name
          product_parent_id
          slug
          product_details
          product_contents
          gtin
          packagingUnit {
            id
            name
          }
          status
        }
      }
      isBundledItem
      hasParentProducts{
        product_qty
        parentProduct{
          slug
          product_name
          gtin
          packagingUnit {
            id
            name
          }
          status
          attributes
        }
      }
      listing{
        id
        product_parent_id
        marketplace_id
        model_name
        besondere_merkmale
        title
        subtitle
        bullets
        targeted_keywords
        monitor_keywords
        backend_keywords
        description
        rich_content
        characteristics
        main_category
        subcategory
        altersempfehlung
        warnhinweis
        images{
          id
          filename
          listing_id
          position
          size
        }
        files{
          id
          filename
          product_parent_id
          position
          size
        }
        updated_at
        setting{
          id
          marketplace_id
          settings
        }
        getSubcategory{
          id
          title
        }
      }
      fbmsize{
        id
        size
        min
        max
        uid
      }
      linkedItems{
        id
        product_parent_id
        variation_product_parent_id
        product{
          product_name
          product_parent_id
          slug
          product_details
          product_contents
          product_category_amazon
          product_category_ebay
          product_category_kaufland
          product_category_otto
          attribute_values
          packagingUnit {
            id
            name
          }
          status
        }
      }
      parentProduct{
        id
        product_parent_id
        variation_product_parent_id
        parentProduct{
          product_name
          product_parent_id
          slug
          gtin
          attributes
          product_details
          product_contents
          product_category_amazon
          product_category_ebay
          product_category_kaufland
          product_category_otto
          packagingUnit {
            id
            name
          }
          status
        }
      }
      biozid
      inhaltsstoffe
      allergene
      lot_number_required
      expiration_date
      marketplace_errors
      safety_data_sheet
      gs1_certificate
      live_images
      warnings_and_instructions
      video_material
    }
  }
`;

export const getProductSettings = gql`
  query {
    productSettings
  }
`;

export const searchDocumentUploads = gql`
query searchDocumentUploads(
  $first: Int!
  $page: Int!
  $input: String
  $order: String!
  $filter: String
) {
  searchDocumentUploads(
    first: $first
    page: $page
    input: { search: $input, filters: $filter }
    orderBy: { field: $order, order: DESC }
  ) {
    paginatorInfo {
      total
      hasMorePages
      count
      lastPage
      currentPage
      lastItem
      perPage
    }
    data {
      id
      name
      type
      folder
      created_at
      user {
        first_name
        last_name
      }
      status
    }
  }
}
`

export const getImportJobs = gql`
  query searchImportJobs(
    $first: Int!
    $page: Int!
    $input: String
    $order: String!
    $filter: String
  ) {
    searchImportJobs(
      first: $first
      page: $page
      input: { search: $input, filters: $filter }
      orderBy: { field: $order, order: DESC }
    ) {
      paginatorInfo {
        total
        hasMorePages
        count
        lastPage
        currentPage
        lastItem
        perPage
      }
      data {
        id
        name
        type
        created_at
        user {
          first_name
          last_name
        }
        count
        countCids
        countPrices
        countBundled
        countContent
        status
      }
    }
  }
`;

export const getImportJob = gql`
  query importJob($id: ID!, $first: Int!, $page: Int!, $product_name: String) {
    importJob(id: $id) {
      id
      name
      type
      user {
        first_name
        last_name
      }
      created_at
      status
      productTemps(first: $first, page: $page, product_name: $product_name) {
        paginatorInfo {
          total
          hasMorePages
          count
          lastPage
          currentPage
          lastItem
          perPage
        }
        data {
          slug
          product_parent_id
          manufacturer
          brand
          product_name
          product_details
          product_contents
          packaging_unit
          product_type
          error
          manufacturer_product_id
          pzn
          packaging_logistical
          gtin
          status
          article_width_mm
          article_depth_mm
          article_height_mm
          logistics_ve_height
          logistics_ve_width
          logistics_ve_depth
          logistics_ve_weight
          logistics_ve_unit_volume
          packaging_unit_per_eur_pallet
          article_weight_g
          article_packaging_type
          product_category_pharmacy
          product_category_hazmat
          product_category_electronics
          product_category_food
        }
      }
      productCidsImportTable(first: $first, page: $page, product_name: $product_name) {
        paginatorInfo {
          total
          hasMorePages
          count
          lastPage
          currentPage
          lastItem
          perPage
        }
        data {
          slug
          product_parent_id
          manufacturer
          brand
          product_name
          product_details
          product_contents
          packaging_unit
          product_type
          error
          id
          marketplace_name
          marketplace_id_type
          fulfillment_vendor
          marketplace_id
          fulfillment_vendor_number_id
          seller
          status_marketplace
          product_child_id
          fee_category{
            id
            name
          }
        }
      }
      productPriceImportTable(first: $first, page: $page, product_name: $product_name) {
        paginatorInfo {
          total
          hasMorePages
          count
          lastPage
          currentPage
          lastItem
          perPage
        }
        data {
          slug
          product_parent_id
          manufacturer
          brand
          product_name
          product_details
          product_contents
          packaging_unit
          product_type
          marketplace_name
          error
          id
          tax_rate
          price_buy_net_actual_ct
          tax_code
          rrp_value
          additional_costs
          manufacturer_margin
        }
      }
      productBundleImportTable(first: $first, page: $page, product_name: $product_name) {
        paginatorInfo {
          total
          hasMorePages
          count
          lastPage
          currentPage
          lastItem
          perPage
        }
        data {
          product_parent_id
          bundled_item_pid
          qty
          error
          product{
            product_parent_id
            product_name
            slug
            product_contents
            manufacturer {
              name
            }
            brand {
              name
            }
            packagingUnit {
              name
            }
            type {
              name
            }
          }
          bundledProduct{
            product_name
            slug
            packagingUnit {
              name
            }
          }
        }
      }
      productContentImportTable(first: $first, page: $page, product_name: $product_name) {
        paginatorInfo {
          total
          hasMorePages
          count
          lastPage
          currentPage
          lastItem
          perPage
        }
        data {
          product_parent_id
          product_name
          marketplace_name
          product{
            slug
            product_parent_id
            product_name
          }
          marketplace{
            id
            name
          }
          contentSetting{
            settings
          }
          listing_title
          listing_bullet_1
          listing_bullet_2
          listing_bullet_3
          listing_bullet_4
          listing_bullet_5
          listing_bullet_6
          listing_bullet_7
          listing_bullet_8
          listing_bullet_9
          listing_bullet_10
          targeted_keywords
          backend_keywords
          listing_desc
          error
          subtitle
          rich_content
          characteristics
          main_category
          subcategory
        }
      }
    }
  }
`;

/** ---------- PIM SETTINGS ------------ */

export const getMarketplacesSettings = gql`
  query marketplaces($order: String!) {
    marketplaces(orderBy: { field: $order, order: ASC }) {
      id
      name
      tax_rate
      currency_id
      status
    }
  }
`;

export const getFulfillmentVendorsSettings = gql`
  query fulfillmentVendors($order: String!) {
    fulfillmentVendors(orderBy: { field: $order, order: ASC }) {
      id
      name
      status
    }
  }
`;

export const getProductTypesSettings = gql`
  query productsTypes($order: String!) {
    productsTypes(orderBy: { field: $order, order: ASC }) {
      id
      name
      status
    }
  }
`;

export const getPackagingUnitsSettings = gql`
  query packagingUnits($order: String!) {
    packagingUnits(orderBy: { field: $order, order: ASC }) {
      id
      name
      status
    }
  }
`;

export const getProductCategoriesSettings = gql`
  query productCategories($order: String!) {
    productCategories(orderBy: { field: $order, order: ASC }) {
      id
      name
      category
      status
    }
  }
`;

export const getBaseUnitsSettings = gql`
  query baseUnits($order: String!) {
    baseUnits(orderBy: { field: $order, order: ASC }) {
      id
      name
      status
    }
  }
`;

export const getArticlePackagingTypes = gql`
  query articlePackagingTypes($order: String!) {
    articlePackagingTypes(orderBy: { field: $order, order: ASC }) {
      id
      name
      status
    }
  }
`;

export const getSellers = gql`
  query sellers($order: String!) {
    sellers(orderBy: { field: $order, order: ASC }) {
      id
      name
      short_name
      account_type
      status
    }
  }
`;

export const getShippingUnits = gql`
  query {
    shippingUnits{
      id
      name
      status
    }
  }
`;

export const getShippingCarriers = gql`
  query {
    shippingCarriers{
      id
      name
      status
    }
  }
`;


export const getShippingUnitsTemp = gql`
  query {
    shippingUnitsTemp{
      id
      unit_size
      unit_weight
      sfp_uid
      weight_g
      dimension_width_mm
      dimension_height_mm
      dimension_depth_mm
      dimension_operator
      sfp_dimensions_min
      sfp_dimensions_max
      fulfillment_vendor
      shipping_carrier
      marketplace
      currency
      status
    }
  }
`;


export const getFulfilmentCosts = gql`
  query {
    fulfilmentCosts{
      id
      shipping_carrier
      weight_g
      cost_order_received
      bundle_article_pick
      single_article_pick
      cost_order_shipping
      cost_return_article
      date_range
      unit_data
      cost_article_returned_percentage
      cost_article_shipped_percentage
      surcharge
      status
    }
  }
`;

export const getWarehouseCosts = gql`
  query {
    warehouseCosts{
      id
      fulfilment_vendor
      currency
      period
      cost
      margin_percentage
      cost_sell
      error
      vendor{
        id
        name
      }
    }
  }
`;


export const getMarketpalceCosts = gql`
  query {
    marketplaceCosts{
      id
      marketplace_id
      category_id
      period
      category_fee
      price_from
      price_to
      error
      marketplace{
        id
        name
      }
      category{
        id
        name
        category
      }
    }
  }
`;

/** ---------- FILTER  ------------ */

export const filter = gql`
  query filter($type: String!, $manufacturer_id: [Int]) {
    filter(type: $type, manufacturer_id: $manufacturer_id) {
      type
      data
      name
      alias
    }
  }
`;

/**  ------------- Translation --------------   */

export const getTranslations = gql`
  query getTranslation($page: String) {
    getTranslation(input: { page: $page, language: EN }) {
      id
      page
      value
      translation
      language
    }
  }
`;

export const getAllReport = gql`
  query {
      getAllReport{
        id
        reportName
        reportPage
        reportId
        lastRefresh
        embeddedLocation
        sectionStatus
        showFilter
        showFlag
        showExplanation
        explanation_url
      }
    }
`;


export const getSections = gql`

  query($filter: Int) {
      getSections(filter: $filter) {
        id
        section
        subsection
        tab
        status
        showFilter
        showFlag
        position
        user_tab_position
        roleId
        showExplanation
        explanation_url
      }
    }
`;

export const getSection = gql`
  query getSection($section: String, $subsection: String, $filter: Int) {
    getSection(section: $section, subsection: $subsection, filter: $filter ) {
      id
      section
      subsection
      tab
      showFilter
      showFlag
      status
      position
      user_tab_position
      showExplanation
      explanation_url
    }
  }
`;

export const getSectionWithReports = gql`
  query getSectionWithReports($section: String, $subsection: String) {
    getSectionWithReports(section: $section, subsection: $subsection) {
      id
      section
      subsection
      tab
      showFilter
      showFlag
      status
      position
      user_tab_position
      showExplanation
      explanation_url
      report{
        id
        reportName
        reportPage
        reportId
        embeddedLocation
        embedUrl
        showFilter
        showFlag
        showExplanation
        explanation_url
        error
      }
    }
  }
`;

export const getAllSectionWithReports = gql`
  query getAllSectionWithReports($section: String, $subsection: String) {
    getAllSectionWithReports(section: $section, subsection: $subsection) {
      id
      section
      subsection
      tab
      showFilter
      showFlag
      status
      position
      user_tab_position
      roleId
      showExplanation
      explanation_url
      report{
        id
        reportName
        reportPage
        reportId
        embeddedLocation
        embedUrl
        showFilter
        showFlag
        showExplanation
        explanation_url
        error
      }
    }
  }
`;




// analytics/sales/reports
export const getReport = gql`
  query getReport($url: String) {
      getReport(url: $url) {
        id
        reportName
        reportPage
        reportId
        embeddedLocation
        embedUrl
        showFilter
        showFlag
        showExplanation
        explanation_url
        error
      }
  }
`;

export const getReportByID = gql`
  query getReportByID($id: String!) {
      getReportByID(id: $id) {
        id
        reportName
        reportPage
        reportId
        embeddedLocation
        embedUrl
        showFilter
        showFlag
        showExplanation
        explanation_url
        error
      }
  }
`;

export const getReportByReportName = gql`
  query getReportByReportName($name: String!) {
    getReportByReportName(name: $name) {
        id
        reportName
        reportPage
        reportId
        embeddedLocation
        embedUrl
        showFilter
        showFlag
        showExplanation
        explanation_url
        error
      }
  }
`;


export const getToken = gql`
  query {
    getToken
  }
`;

export const getAllContentSettings = gql`
  query allContentSettings{
    allContentSettings{
      id
      marketplace_id
      settings
    }
  }
`;


export const getContentSettings = gql`
  query contentSettings($id: Int!){
    contentSettings(id: $id){
      id
      marketplace_id
      settings
    }
  }
`;

export const getSearchListings = gql`
  query searchListings(
    $first: Int!
    $page: Int!
    $input: String
    $order: String!
    $filter: String
  ) {
    searchListings(
      first: $first
      page: $page
      input: { search: $input, filters: $filter }
      orderBy: { field: $order, order: DESC }
    ) {
      paginatorInfo {
        total
        hasMorePages
        count
        lastPage
        currentPage
        lastItem
        perPage
      }
      data {
        id
        marketplace_id
        marketplace_id
        model_name
        besondere_merkmale
        title
        subtitle
        bullets
        targeted_keywords
        monitor_keywords
        backend_keywords
        description
        rich_content
        characteristics
        main_category
        subcategory
        images{
          id
          filename
          listing_id
          position
          size
        }
        files{
          id
          filename
          product_parent_id
          position
          size
        }
        updated_at
        setting{
            settings
        }
        getSubcategory{
          id
          title
        }
        product {
          slug
          product_name
          product_parent_id
          manufacturer {
            name
          }
        }
        marketplace{
          name
        }
      }
    }
  }
`;

export const getListing = gql`
  query searchProductMarketplaces(
    $first: Int!
    $page: Int!
    $input: String
    $order: String!
    $filter: String
  ) {
    searchProductMarketplaces(
      first: $first
      page: $page
      input: { search: $input, filters: $filter }
      orderBy: { field: $order, order: DESC }
    ) {
      paginatorInfo {
        total
        hasMorePages
        count
        lastPage
        currentPage
        lastItem
        perPage
      }
      data {
        marketplace_id
        product {
          slug
          product_name
          product_parent_id
          manufacturer {
            name
          }
          listing{
            marketplace_id
            model_name
            besondere_merkmale
            title
            subtitle
            bullets
            targeted_keywords
            monitor_keywords
            backend_keywords
            description
            rich_content
            characteristics
            main_category
            subcategory
            images{
              id
              filename
              listing_id
              position
              size
            }
            files{
              id
              filename
              product_parent_id
              position
              size
            }
            updated_at
            setting{
                settings
            }
            getSubcategory{
              id
              title
            }
          }
        }
        marketplace{
          name
        }
      }
    }
  }
`;

export const getBackup = gql`
  query getBackup{
    getBackup{
      id
      error
      started_at
      finished_at
    }
  }
`;

export const getFBMSizes = gql`
  query getFBMSizes{
    getFBMSizes{
      id
      size
      min
      max
      uid
    }
  }
`;

export const getFBADimensions = gql`
  query getFBADimensions{
    getFBADimensions{
      id
      units
      dimensions
      operator
      weight
    }
  }
`;

export const getCopyData = gql`
  query getCopyData{
    getCopyData{
      id
      error
      row_count
      started_at
      finished_at
    }
  }
`;

export const getAttributes = gql`
  query attributes{
    attributes{
      id
      attribute
      attribute_name
      manufacturer_id
      status
      manufacturer{
        id
        name
      }
    }
  }
`;


export const getExchangeRates = gql`
  query exchange_rates{
    exchange_rates{
      id
      base
      value
      currency_id
    }
  }
`;

export const getListingCategories = gql`
  query listing_categories{
    listing_categories{  
      id
      marketplace_id
      title_singular
      level
      id_category
      id_parent_category
    }
  }
`;

export const getOttoCategories = gql`
  query otto_categories{
    otto_categories{  
      id
      parent_category_id
      title
      level
    }
  }
`;

export const getEbayCategories = gql`
  query ebay_categories{
    ebay_categories{  
      id
      parent_category_id
      title
      level
    }
  }
`;

export const getAmazonListingStatus = gql`
  query getAmazonMerchantListing($sku: String!, $marketplaceId: Int!){
    getAmazonMerchantListing(sku: $sku, marketplaceId: $marketplaceId){
      seller_sku
      status
    }
  }
`;

export const getAmazonListingsStatuses = gql`
  query getAmazonMerchantListings($input: [AmazonMerchantListingInput]){
    getAmazonMerchantListings(input: $input){
      seller_sku
      marketplace
      status
    }
  }
`;

export const unitEconomics = gql`
  query unit_economics($pid: Int){
    unit_economics(pid: $pid){  
      id
      marketplace_id
      fulfillment_vendor_id
      sku
      packaging_type_id
      manufacturer_margin
      currency_id
      cost_sell_price_article_order_received_ct
      cost_sell_price_article_order_pick_single_ct
      cost_sell_price_article_order_pick_bundle_ct
      cost_sell_price_article_shipped_ct
      cost_sell_price_warehouse_cost_cm3_ct
      cost_sell_price_warehouse_cost_per_product_ct
      cost_sell_price_article_shipped_total_ct
      cost_sell_price_article_returned_total_ct
      additional_cost_ct
      sell_price_net_ct
      sell_price_gross_ct
      manufacturer_profit_ct
      marketplace_cost_ct
      price_buy_net_actual_ct
      price_type
      error
    }
  }
`;

export const getImportProductsCurrentData = gql`
  query currentProducts($input: [CurrentProductInput]) {
    currentProducts(input: $input) {
      product_child_id
      marketplace_id
      marketplace_product_id
      marketplace_id_type
      status
      skuFullFilmentVendors {
        fulfillment_vendor_id
        inventory_id
        fulfillment{
          id
          name
        }
      }
      seller {
        id
        name
      }
      marketplace { 
        id
        name
      }
    }
  }
`;
  
export const getProductLogs = gql`
  query productLogs($slug: String, $externalSystems: [String]){
    productLogs(slug: $slug, externalSystems: $externalSystems){
      product_id 
      external_system
      action
      error
      updated_at
    }
  }
`;

export const advertisingChannels = gql`
  query advertising_channels{
    advertising_channels{  
      id
      channel
    }
  }
`;

export const getPortfolios = gql`
  query getPortfolios($manufacturer: String){
    getPortfolios(manufacturer: $manufacturer){
      id
      name
      status
    }
  }
`;
