import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';

const TableHeader = ({ titles, page = '', content, setSelectedItems, hasAction, isListing }) => {
  const translate = useSelector(state => state.translation.translate);
  const isProduct = page === 'products';
  const isBackup = page === 'backup';
  /** Initial Fields */
  const [fields, setFields] = useState([]);
  useEffect(() => {
    let fieldsArray = [];

    titles.forEach(key => {
      let fieldName = key;

      if (translate[page] && translate[page][key]) {
        fieldName = translate[page][key];
      } else {
        if (translate.global) {
          fieldName = translate.global[key] ? translate.global[key] : fieldName;
        }
      }

      if(fieldName.includes("percentage")){
        fieldName = fieldName.split('_')[0]
      }

      fieldsArray.push(fieldName);
    });
    // need fix
    if (page === 'analyticsReports') {
      fieldsArray = [
        'id',
        'Report Name',
        'Report Page',
        'Report ID',
        'Last Refresh',
        'Embedded Location',
      ]
    }
    if(page == 'analyticsSections'){
      fieldsArray = [
        '',
        'section',
        'Subsection',
        'Tab',
        'Status'
      ]
    }
    if (page === 'DocumentUploads') {
      fieldsArray = [
        'ID',
        'Import name',
        'Type',
        'Uploaded folder',
        'Created at',
        'Created by',
        'Status',
      ]
    }
    if (page === 'manufacturers') {
      fieldsArray = [
        'id',
        'Name',
        'Company type',
        'Service type',
        'Sales comission type',
        'Sales commission',
        'Sales status',
        'Advertising status'
      ]
    }

    setFields(fieldsArray);
  }, [titles, page, translate]);

  const checkboxChangeHandler = event => {
    const { checked } = event.target;
    if (checked) {
      setSelectedItems(content.map(item => +item.product_parent_id));
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <thead>
      <tr>
        {(isProduct || isListing) && fields.length ? (
          <th className="text-center">
            <input type="checkbox" onChange={checkboxChangeHandler} />
          </th>
        ) : null}
        {fields.map((field, index) => {
          if(field !== 'slug' && field !== 'alt_name' && field !== 'user_tab_position'  && field !== 'product_type_id' && field !== 'showFlag' && field !== 'roleId'){
            return <th className="text-capitalize" key={index}>
                {field.replace('product_parent_id', 'ID').replaceAll('_', ' ')}
            </th>;
          }
        })}
        {hasAction && (
          <th className="text-center">Actions</th>
        )}
      </tr>
    </thead>
  );
};

export default memo(TableHeader);
