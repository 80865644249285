import React from 'react';
import Select from 'react-select';
import ChildInput from './ChildInput';
import DatePicker from 'react-datepicker';

const Input = ({
  data,
  inputChanged,
  col,
  childInputChanged,
  required = false,
  inlineLabel,
  expandedHeader
}) => {
  let drawInput = '';

  const isIterable = obj => {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }

  switch (data.inputType) {
    case 'input':
      drawInput = (
        <>
          <label htmlFor={data.inputConf.name} className={inlineLabel ? 'mb-0 mr-2' : ''}>
            {data.label}
          </label>
          <input
            type={data.inputConf.type}
            id={data.inputConf.name}
            className="form-control"
            placeholder={data.inputConf.placeholder}
            defaultValue={data.value}
            onChange={inputChanged}
            required={required}
            step={data.inputConf.type === 'number' ? '0.01' : undefined}
            min="0"
            max="100"
          />
        </>
      );
      break;

    case 'dynamicInput':
      drawInput = (
        <>
          <label htmlFor={data.inputConf.name}>{data.label}</label>

          {/* <div className="row pl-2 pr-2">
                    <input 
                        type={data.inputConf.type}
                        id={data.inputConf.name}
                        className="form-control col-md-10"
                        placeholder={data.inputConf.placeholder}
                        defaultValue={data.value}
                        onChange = { inputChanged }
                        required={true}
                    />

                    <button type='button' className="form-control col-md-1 offset-md-1">+</button> 
                </div> */}

          <ChildInput data={data} updatedFields={childInputChanged} />
        </>
      );
      break;

    case 'textarea':
      drawInput = (
        <>
          <label htmlFor={data.inputConf.name}>{data.label}</label>
          <textarea
            className="form-control"
            id={data.inputConf.name}
            rows="5"
            required={required}
            onChange={inputChanged}
            defaultValue={data.value}
          ></textarea>
        </>
      );
      break;

    case 'select':
      console.log(data.value, data.label, !isIterable(data.value) || data.value?.length)
      drawInput = (
        <>
          <label>{data.label}</label>
          <Select value={data.value?.length == 0 ? null : data.list.filter(x => data.value.some(w => w.value == x.value))} onChange={inputChanged} options={data.list} isMulti />
        </>
      );

      break;

    case 'selectSingleTarget':
      drawInput = (
        <>
          <label className={inlineLabel ? 'mb-0 mr-2' : ''} style={{ whiteSpace: 'nowrap' }}>
            {data.label}
          </label>
          <Select
            className="w-100"
            defaultValue={data.value}
            onChange={inputChanged}
            options={data.list}
          />
        </>
      );

      break;

    case 'checkbox':
      drawInput = (
        <>
          <input
            type="checkbox"
            onChange={inputChanged}
            defaultValue={data.value}
            defaultChecked={data.value}
            className="custom-control-input"
            id={data.inputConf.name}
          />
          <label htmlFor={data.inputConf.name} className="custom-control-label">
            {data.label}
          </label>
        </>
      );

      break;

      case 'datepicker':
        drawInput = (
          <>
            <DatePicker
              selectsRange={true}
              startDate={data.value}
              endDate={data.value}
              isClearable={true}
              selected={data.value}
              onChange={inputChanged}
              className="form-control"
            />
            <label htmlFor={data.inputConf.name} className="custom-control-label">
              {data.label}
            </label>
          </>
        );
  
        break;

    default:
      drawInput = (
        <>
          <label htmlFor="firstName"> First Name </label>
          <input type="text" id="firstName" className="form-control" />
        </>
      );
      break;
  }

  let width = '25%';

  if (expandedHeader) {
    width = '14%';
  } else if (data.inputConf.name === 'count') {
    width = '12%';
  }

  return (
    <div
      className={`${col ? 'col-md-3' : ''} ${
        inlineLabel ? 'd-flex align-items-center' : 'form-group mb-2'
      }`}
      style={{ float: 'left', width, minWidth: '180px' }}
    >
      {data.inputConf.type !== 'hidden' ? drawInput : ''}
    </div>
  );
};

export default Input;
