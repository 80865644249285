import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import Table from '../../components/table/Table';

import { getSearchListings as QUERY } from '../../services/api/queryService';
import { queryStringHandler, fetchData } from '../../services/util/dataHandlerService';
import Routes from '../../lib/constants/routes';

const description = {
  module: 'products',
  description: 'Listings',
  req: 'searchListings',
  url: Routes.products.index,
  tab: 'listings',
  is_product: false
};

const ListingSearchTab = ({ canDelete, location }) => {
  const { user_type, manufacturers } = useSelector(state => state.auth.user);
  const { first, page, keyword, filter } = queryStringHandler(location.search);
  const [roleFilter, setRoleFilter] = useState(''); 
  const [ activeManufacturers, setActiveManufacturers] = useState([]);

  useEffect(() => {
    const storageActiveManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
    if(storageActiveManufacturers){
      setActiveManufacturers(storageActiveManufacturers);
    }
  }, []);


  window.addEventListener('activeManufacturerChange', (e) => {
    setActiveManufacturers(JSON.parse(localStorage.getItem('active_manufacturers')));
  }); 

  useEffect(() => { 
    if(user_type === 1){
        setRoleFilter(manufacturers.map(id => `manufacturer_id[]=${id}`).join('&'))
      }
    if(user_type === 2 && activeManufacturers){
        setRoleFilter(activeManufacturers.map(aManu => `manufacturer_id[]=${aManu.id}`).join('&'))
      }
  }, [activeManufacturers]);

  let { loading, data, refetch, error } = useQuery(QUERY, {
    variables: {
      first,
      page,
      input: keyword,
      order: 'product_parent_id',
      filter: filter ? `${filter}&${roleFilter}` : roleFilter
    },
    fetchPolicy: 'no-cache'
  });

    if (data) {
      const productsData = Object.values(data)[0].data;
      const arr = []

      productsData.forEach(product => {

        if(product){
          if(product.product){
            product.listing = product
            product.slug = product.product.slug
            product.manufacturer = product.product.manufacturer.name
            product.product_name = product.product.product_name
            product.product_parent_id = product.product.product_parent_id
            product.marketplace_name = product.marketplace.name
          }else{
            product.slug = product.slug
            product.manufacturer = product.manufacturer
            product.product_name = product.product_name
            product.product_parent_id = product.product_parent_id
            product.marketplace_name = product.marketplace_name
          }


          const item = {

            'product_parent_id': product.id,
            'slug': product.slug,
            'manufacturer': product.manufacturer,
            'marketplace_name': product.marketplace_name,
            'product_name': product.product_name
          }

          let settings = null;
          if(product.listing){
            const currListing = product
            if(currListing){
              settings = JSON.parse(currListing.setting.settings)
              item.title =  `${currListing.title?.split(' ').filter(x => x.length).length}/${settings ? settings.title_words_max : ''}`
              item.bullets =  `${currListing.bullets?.split(';').filter(x => x.length > 0).length}/${settings ? settings.bulltets_count_max??0 : ''}`
              // item.targeted_keywords =  `${currListing.targeted_keywords ? currListing.targeted_keywords.split(',').filter(x => x.length).length : 0}`
              // item.monitor_keywords =  `${currListing.monitor_keywords ? currListing.monitor_keywords.split(',').filter(x => x.length).length : 0}`
              item.backend_keywords =  `${currListing.backend_keywords ? currListing.backend_keywords.split(',').filter(x => x.length).length : 0}`
              item.description =  `${currListing.description ? currListing.description.length : 0}/${settings ? settings.characters_max : ''}`
              item.images =  `${currListing.images ? currListing.images.length : 0}`
              item.documents = `${currListing.files ? currListing.files.length : 0}`
              item.last_modified = currListing.updated_at
            }
          }else{
            item.title = product.title
            item.bullets =  product.bullets
            item.targeted_keywords =  product.targeted_keywords
            // item.monitor_keywords =  product.monitor_keywords
            item.backend_keywords =  product.backend_keywords
            item.description =  product.description
            item.images =  product.images??0
            item.documents =  product.files??0
            item.last_modified = product.last_modified
          }

          arr.push(item)
        }
      });

      data.searchListings.data = arr
    }


  if (error) {
    throw new Error(error);
  }

  return (
    <>
      {!data ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <Table
          openNewTab={true}
          loading={loading}
          refetch={refetch}
          canDelete={canDelete}
          description={description}
          pagerData={{
            ...fetchData(description.req, data).pagerData,
            search: keyword,
            queryStrFilter: filter,
            tab: description.tab
          }}
          tableData={fetchData(description.req, data).data}
          expandedHeader={true}
          hasExport={true}
          filter={filter}
          isListing={true}
        />
      )}
    </>
  );
};

export default ListingSearchTab;
