import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Apollo */
import ApolloClient from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { createUploadLink as CreateUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
/** layout */
import TopBar from './components/layout/topBar';
import SideBar from './components/layout/SideBar';
import Footer from './components/layout/Footer';

/** Actual Pages */
import Auth from './view/auth/Auth';
import Notification from './components/notification/Notification';
import Manufacturer from './view/manufacturer/Manufacturer';
import AddManufacturer from './view/manufacturer/AddManufacturer';
import EditManufacturer from './view/manufacturer/EditManufacturer';
import Product from './view/product/Product';
import AddProduct from './view/product/AddProduct';
import EditProduct from './view/product/EditProduct';
import EditListing from './view/listings/EditListing';
import ImportJob from './view/product/import/ImportJob';
import PimSettings from './view/pimSettings/PimSettings';
import UserManagement from './view/userManagement/UserManagement';
import AnalyticsSettings from './view/analyticsSettings/AnalyticsSettings';
import Sales from './view/analytics/Sales';
import Advertising from './view/analytics/Advertising';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import Fallback from './components/error-boundary/Fallback';
import ContetSettings from './view/contentSettings/ContentSettings';

/** libs */
import { PrivateRoutes } from './lib/hoc/PrivateRoutes';
import routes from './lib/constants/routes';
import { handlePermissions } from './services/util/dataHandlerService';
import Listing from './view/listings/Listings';
import BackUp from './view/backup/BackUp';
import CopyData from './view/backup/CopyData';
import CostSettings from './view/costSettings/CostSettings';
import UnitEconomics from './view/unitEconomics/UnitEconomics';
import ProductLogs from './view/productLogs/ProductLogs';
import DocumentUploadList from './view/documentUpload/documentUploadList';

const { localStorage } = window;

let apiUrl = 'https://staging-api.ebazaaris.io/graphql'
if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'prod_azure') {
  apiUrl = 'https://azure-api.ebazaaris.io/graphql'
} else if (process.env.REACT_APP_ENV === 'dev') {
  apiUrl = 'http://localhost:8000/graphql'
}

export const appUri = apiUrl
const link = new CreateUploadLink({
  // uri: 'http://localhost:8000/graphql',

  uri:  appUri,
  headers: {
    Authorization: localStorage.getItem('auth_token')
      ? `Bearer ${localStorage.getItem('auth_token')}`
      : ''
  }
});



const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});


const App = () => {
  const [activeTitle, setActiveTitle] = useState(null); 

  //Saving user data to the local storage
  const userData = useSelector(state => state.auth.user);
  localStorage.setItem('userData', JSON.stringify(userData));

  const userRoles = useSelector(state => state.auth.user.roles);
  const userPermissions = handlePermissions(userRoles);

  // if(userRoles && userRoles.filter(x => x.id == 52).length == 0){
  //   const keys = Object.keys(userPermissions)

  //   keys.map(x => {
  //     userPermissions[x] = {
  //       read: userPermissions[x].read,
  //       create: false,
  //       import: false,
  //       update: false,
  //       delete: false,
  //     }
  //   })

  // }

  const permissions = []

  if(userRoles){
    userRoles.map(x => {
      x.permissions.map(w => permissions.push(w))
    })
  }



  const df = title => {
    setActiveTitle(title)
  }

  const powerbiToken = localStorage.getItem('powerbi_token')
  const powerBiTokenGenerator = (exp_datetime) => {
    if(exp_datetime < +(new Date().getTime()).toString().substring(0, exp_datetime.length)){
      const appUri = 'https://azure-api.ebazaaris.io/api/power-bi';
      fetch(appUri)
      .then(response => response.json())
      .then(data => localStorage.setItem('powerbi_token', JSON.stringify({
        token: data.access_token,
        expires_on:  data.expires_on
      })));
    }
  }

  if(powerbiToken){
    powerBiTokenGenerator(JSON.parse(powerbiToken).expires_on)
  }else{
    powerBiTokenGenerator(1087178)
  }

  const resetTitle = () => {
    setActiveTitle(null)

    if(powerbiToken){
      powerBiTokenGenerator(JSON.parse(powerbiToken).expires_on)
    }

    console.log('reseted')
  }


  return (
    <ApolloProvider client={client} fetchPolicy="network-only">
      <Router>
        <Notification />
        <Route exact path="/login" component={Auth} />
        <PrivateRoutes>
          <TopBar title={activeTitle} />
          {userRoles ? (

            <>
              <SideBar userPermissions={userPermissions} permissions={permissions} user={userData} resetTitleHandler={resetTitle}/>
              <div className="content-page">
                <div className="content">
                  <ErrorBoundary>
                    <Switch>
                    <Route exact path={routes.analytics.index}  render={routeProps => (
                          <Sales
                            manufacturers={userData.manufacturersArray}
                            {...routeProps}
                          />
                        )} />

                      {/** manufacturer module */}
                      {userPermissions[21].read && (
                        <Route
                          exact
                          key={routes.manufacturers.index}
                          path={routes.manufacturers.index}
                          render={routeProps => (
                            <Manufacturer
                              create={userPermissions[21].create}
                              update={userPermissions[21].update}
                              canDelete={userPermissions[21]}
                              {...routeProps}
                            />
                          )}
                        />
                      )}
                      {userPermissions[21].create && (
                        <Route
                          exact
                          key={routes.manufacturers.create()}
                          path={routes.manufacturers.create()}
                          component={AddManufacturer}
                        />
                      )}
                      {userPermissions[21].read && (
                        <Route
                          exact
                          key={routes.manufacturers.edit()}
                          path={routes.manufacturers.edit()}
                          render={routeProps => (
                            <EditManufacturer
                              create={userPermissions[21].create}
                              update={userPermissions[21].update}
                              canDelete={userPermissions[21]}
                              productsTab={userPermissions[26]}
                              brandsTab={userPermissions[27]}
                              targetsTab={userPermissions[28]}
                              settingTab={userPermissions[29]}
                              getManufacturerTitle={df}
                              {...routeProps}
                            />
                          )}
                        />
                      )}

                      {/** product module */}
                      {userPermissions[22].read && (
                        <Route
                          exact
                          key={routes.products.index}
                          path={routes.products.index}
                          render={routeProps => (
                            <Product
                              canImport={userPermissions[22].import}
                              listingPermissions={userPermissions[22]}
                              create={userPermissions[22].create}
                              update={userPermissions[22].update}
                              canDelete={userPermissions[22]}
                              marketplacePermission={userPermissions[23]}
                              pricePermission={userPermissions[24]}
                              {...routeProps}
                            />
                          )}
                        />
                      )}
                      {userPermissions[22].create && (
                        <Route
                          exact
                          key={routes.products.create()}
                          path={routes.products.create()}
                          render={routeProps => (
                            <AddProduct create={userPermissions[22].create} {...routeProps} />
                          )}
                        />
                      )}
                      {userPermissions[22].read && (
                        <Route
                          exact
                          key={routes.products.edit()}
                          path={routes.products.edit()}
                          render={routeProps => (
                            <EditProduct
                              marketplacePermission={userPermissions[23]}
                              pricePermission={userPermissions[24]}
                              getProductTitle={df}
                              update={userPermissions[22].update} {...routeProps} />
                          )}
                        />
                      )}
                      {userPermissions[22].create && (
                        <Route
                          exact
                          key={routes.products.importJob()}
                          path={routes.products.importJob()}
                          component={ImportJob}
                        />
                      )}

                      {/** listing module */}
                      {userPermissions[25].read && (
                        <Route
                          exact
                          key={routes.listings.index}
                          path={routes.listings.index}
                          render={routeProps => (
                            <Listing
                              create={userPermissions[25].create}
                              update={userPermissions[25].update}
                              canDelete={userPermissions[25]}
                              {...routeProps}
                            />
                          )}
                        />
                      )}
                      {userPermissions[25].create && (
                        <Route
                          exact
                          key={routes.listings.create()}
                          path={routes.listings.create()}
                          render={routeProps => (
                            <AddProduct create={userPermissions[25].create} {...routeProps} />
                          )}
                        />
                      )}
                      {userPermissions[25].read && (
                        <Route
                          exact
                          key={routes.listings.edit()}
                          path={routes.listings.edit()}
                          render={routeProps => (
                            <EditListing update={userPermissions[25].update} {...routeProps}
                            getProductTitle={df}
                            />
                          )}
                        />
                      )}

                      {userPermissions[25].read && (
                        <Route
                          exact
                          key={routes.listings.images()}
                          path={routes.listings.images()}
                          render={routeProps => (
                            <EditListing defaultActiveTab="images" update={userPermissions[25].update} {...routeProps}
                            getProductTitle={df}
                            />
                          )}
                        />
                      )}



                      {userPermissions[25].read && (
                        <Route
                          exact
                          key={routes.unitEconomics.edit()}
                          path={routes.unitEconomics.edit()}
                          render={routeProps => (
                            <UnitEconomics update={userPermissions[25].update} {...routeProps} 
                            getProductTitle={df}
                            />
                          )}
                        />
                      )}

                      {userPermissions[25].read && (
                        <Route
                          exact
                          key={routes.productLogs.index}
                          path={routes.productLogs.index}
                          render={routeProps => (
                            <ProductLogs update={userPermissions[25].update} {...routeProps} 
                            getProductTitle={df}
                            />
                          )}
                        />
                      )}  

                      {/** pim settings module */}
                      {userPermissions[31].read && (
                        <Route
                          exact
                          path={routes.userManagement.index}
                          render={routeProps => (
                            <UserManagement
                              create={userPermissions[31].create}
                              update={userPermissions[31].update}
                              {...routeProps}
                            />
                          )}
                        />
                      )}
                      {userPermissions[32].read && (
                        <Route
                          exact
                          path={routes.pimSettings.index}
                          render={routeProps => (
                            <PimSettings
                              create={userPermissions[32].create}
                              update={userPermissions[32].update}
                              {...routeProps}
                            />
                          )}
                        />
                      )}
                      {userPermissions[33].read && (
                        <Route
                          exact
                          path={routes.analyticsSettings.index}
                          component={AnalyticsSettings}
                        />
                      )}
                      {/* {userPermissions[34].read && (
                        <Route
                          exact
                          path={routes.contentSettings.index}
                          component={ContetSettings}
                        />
                      )} */}

                      {userPermissions[37].read && (
                        <Route
                          exact
                          path={routes.costSettings.index}
                          component={CostSettings}
                        />
                      )}

                      
                      {userPermissions[41].read && (
                        <Route
                          exact
                          path={routes.documentUploads.index}
                          component={DocumentUploadList}
                        />
                      )}
                      <Route exact path="/">
                        <Redirect to={'/analytics?section=retail&subsection=sales'} />
                      </Route>
                      <Fallback is404={true} />
                    </Switch>
                  </ErrorBoundary>
                </div>
                <Footer />
              </div>
            </>
          ) : (
            <div className='init_loader'>
              <div className="spinner">Spinner</div>
              <p>It might take a few moments to load the page</p>
            </div>
          )}
        </PrivateRoutes>
      </Router>
    </ApolloProvider>
  );
};
export default App;
