import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalError from '../../../components/modal/ModalError';
import { getAttributes, getManufacturer } from '../../../services/api/queryService';
import { createProduct, updateProduct } from '../../../services/api/mutationService';
import { PRODUCT_STATUS_OPTIONS, PRODUCT_STATUS_OPTIONS_SHOWN, SEASONALITY } from '../../../lib/constants/fields';
import {
  convertDataAttributesToOptions,
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';

const ProductInformationForm = ({
  selectData,
  productData,
  history,
  refetch,
  create,
  update,
  req,
  updateFormData,
  errors
}) => {
  const isEditMode = !!productData.product_parent_id;
  const dispatch = useDispatch();
  const [
    createProductMutation,
    { loading: createProductLoading, error: createError }
  ] = useMutation(createProduct);
  const [
    updateProductMutation,
    { loading: updateProductLoading, error: updateError }
  ] = useMutation(updateProduct);


  const [formData, setFormData] = useState(isEditMode ? {
    manufacturer_id: productData.manufacturer && productData.manufacturer.id,
    manufacturer_slug: productData.manufacturer && productData.manufacturer.slug,
    brand_id: productData.brand && productData.brand.id,
    status: productData.status,
    product_type_id: productData.type && productData.type.id,
    product_name: productData.product_name,
    product_details: productData.product_details,
    product_contents: productData.product_contents,
    packaging_unit_id: productData.packagingUnit && productData.packagingUnit.id,
    gtin: productData.gtin,
    manufacturer_product_id: productData.manufacturer_product_id,
    base_content_volume: Number(productData.base_content_volume, 2),
    base_unit: productData.base_unit,
    pzn: productData.pzn,
    seasonality: productData.seasonality,
    biozid: productData.biozid,
    inhaltsstoffe: productData.inhaltsstoffe,
    allergene: productData.allergene,
    lot_number_required: productData.lot_number_required,
    expiration_date: productData.expiration_date,
    attributes: productData.attributes && productData.attributes.length > 0 ? productData.attributes.split(',').map(x => ({id: x, name: x})) : []
  } : {
    manufacturer_id: null,
    manufacturer_slug: null,
    brand_id: null,
    status: null,
    product_type_id: 1,
    product_name: null,
    product_details: null,
    product_contents: null,
    packaging_unit_id: null,
    gtin: null,
    manufacturer_product_id: null,
    base_content_volume: null,
    base_unit: null,
    pzn: null,
    seasonality: null,
    biozid: null,
    inhaltsstoffe: null,
    allergene: null,
    lot_number_required: null,
    expiration_date: null,
    attributes: [],
  });
  
  const [error, setError] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [errorsList, setErrorsList] = useState([]);
  const [attributes, setAttributes] = useState(null);
  const [isWarning, setIsWarning] = useState(false);
  
  useEffect(() => {
    let err = {...errors}

    setErrorsList(err)
  }, [errors])
  
  const { localStorage } = window;
  
  let _arr = localStorage.getItem('idarray')
  if(_arr){
    _arr = _arr.split(',')
  }else{
    _arr = []
  }


  const silentError = _arr.indexOf(productData.product_parent_id) < 0 ? false : true

  let brands = [];
  const [fetchQuery, { data: brandsData, loading, error: manufacturerError }] = useLazyQuery(
    getManufacturer,
    {
      variables: { slug: formData.manufacturer_slug },
      fetchPolicy: 'no-cache'
    }
  );

  let { data : getAttributesData } = useQuery(getAttributes);

  useEffect(() => {
    if(getAttributesData){
      const _arr = []

      getAttributesData.attributes.filter(x => +x.manufacturer_id === +formData.manufacturer_id).map(x => {
        if(_arr.indexOf(x.attribute) < 0){
          _arr.push(x.attribute)
        }
      })

      setAttributes([..._arr.map(x => ({ id: x, attribute: x }))])
    }

  }, [getAttributesData, formData])

  if (brandsData) {
    brands = brandsData.manufacturer.brands;
    if (formData.brand_id && !brands.find(brand => brand.id === formData.brand_id)) {
      setFormData({ ...formData, brand_id: undefined });
    }
  }

  useEffect(() => {
    if (formData.manufacturer_id) {
      
      fetchQuery();
    }
    // eslint-disable-next-line
  }, [formData.manufacturer_id, fetchQuery]);

  const submitHandler = async event => {


    const attr = formData.attributes.map(x => x.name).join(',')

    try {
      setErrorArray([])

      const response = isEditMode
        ? await updateProductMutation({
            variables: { ...formData, product_parent_id: productData.product_parent_id, attributes: attr }
          })
        : await createProductMutation({ variables: {...formData, attributes: attr } });
      
      if (isEditMode && response.data.updateProduct.warning) {
        let warningText = JSON.parse(response.data.updateProduct.warning);
        warningText = warningText.join(";");
        setErrorArray([...errorArray, warningText])
      }

      if (!response.data[req]) {
        setErrorArray([...errorArray, 'Server responded with no data'])
        return;
      }

      if(!isEditMode){
        if(response.data.createProduct.error){
          setErrorArray([response.data.createProduct.error])
        }else{
          dispatch({
            type: 'UPDATE',
            payload: {
              type: 'success',
              message: `Recored was successfully ${isEditMode ? 'updated' : 'created'}`
            }
          });
          return history.push(`/products/${response.data.createProduct.slug}/edit`);
        }
      }

        

      refetch();
    } catch (err) {
      console.log(err)
    }

  };

  const changeFieldHandler = event => {
    setChangesMade(true);

    const err = {...errorsList}
    const d = Object.keys(err).find(x => x == event.target.id)

    if(d){
      delete err[event.target.id]

      setErrorsList({...err})
    }

    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const changeMultiSelectHandler = (options, data) => {
    setChangesMade(true);

    setFormData({
      ...formData,
      [data.name]: options ? options.map(option => ({id: option.value, name: option.value})) : []
    });

  };

  const changeSelectHandler = (option, data) => {
    setChangesMade(true);

    const err = {...errorsList}
    const d = Object.keys(err).find(x => x == data.name)

    if(d){
      delete err[data.name]

      setErrorsList({...err})
    }

    if (data.name === 'status' && option.value == 0 && productData.hasParentProducts && productData.hasParentProducts.length > 0 && +formData.product_type_id !== 8) {
      let productNames = '';
      productData.hasParentProducts.forEach((item, index) => {
        if(item.parentProduct){
          productNames += item.parentProduct.product_name;
          productNames += (productData.hasParentProducts[index + 1]) ? ', ' : '';
        }
      })
      setErrorArray([...errorArray, 'This action will change the status of a bundle: ' + productNames + ' to Archived'])
    }

    if(data.name === 'status' && +option.value === 3){
      if(!window.confirm('The status "Phase out" should only be used for products that cannot or should not be reordered, but still be sold. Are you sure that you want to apply this status?')){
        option.value = formData.status
      }
    }

    if (option) {
       if(data.name === 'manufacturer_id'){
         formData.manufacturer_id = option.value
         formData.manufacturer_slug = selectData.manufacturers.find(x => x.id === option.value).slug
        setFormData(formData);
        fetchQuery()
      }else{
        setFormData({
          ...formData,
          [data.name]: option.value
        });
      }
    } else {
      const updatedFormData = { ...formData };
      delete updatedFormData[data.name];
      setFormData(updatedFormData);
    }
  };

  if (createError || updateError || manufacturerError) {
    throw new Error(createError || updateError || manufacturerError);
  }

  const isArchived =
    productData.status === 0 || (productData.manufacturer && productData.manufacturer.status === 0);

    useEffect(() => {
      if(changesMade){

        window.onbeforeunload = function() {
          return true;
        };
    
        return () => {
          window.onbeforeunload = null;
        };
      }
    }, [changesMade]);

    const options = [
      { label: 'Yes', value: 1 },
      { label: 'No', value: 2 }
    ]

  if(productData && +productData.product_type_id > 0 && +productData.product_type_id !== 8){
    selectData.productTypes = selectData.productTypes.filter(x => x.id != 8)
  }

  if(productData && +productData.product_type_id > 0 && +productData.product_type_id == 8){
    selectData.productTypes = selectData.productTypes.filter(x => x.id == 8)
  }

  const showError = key => {
    if(!errorsList[key]) return null

    let styles = {}

    if(key == 'packaging_unit_id'){
      styles = {width: "280px", bottom: '-30px'}
    }

    return (
      <div className="custom_error_container" style={styles}>
        <img src="/assets/images/info-button.png" alt="" />
        <p>{errorsList[key]}</p>
      </div>
    )
  }
  
  useEffect(() => {
    if(isEditMode) updateFormData({productInformation: formData})
  }, [formData])


  useEffect(() => {
    if(isEditMode){
      setFormData({
        manufacturer_id: productData.manufacturer && productData.manufacturer.id,
        manufacturer_slug: productData.manufacturer && productData.manufacturer.slug,
        brand_id: productData.brand && productData.brand.id,
        status: productData.status,
        product_type_id: productData.type && productData.type.id,
        product_name: productData.product_name,
        product_details: productData.product_details,
        product_contents: productData.product_contents,
        packaging_unit_id: productData.packagingUnit && productData.packagingUnit.id,
        gtin: productData.gtin,
        manufacturer_product_id: productData.manufacturer_product_id,
        base_content_volume: Number(productData.base_content_volume, 2),
        base_unit: productData.base_unit,
        pzn: productData.pzn,
        seasonality: productData.seasonality,
        biozid: productData.biozid,
        inhaltsstoffe: productData.inhaltsstoffe,
        allergene: productData.allergene,
        lot_number_required: productData.lot_number_required,
        expiration_date: productData.expiration_date,
        attributes: productData.attributes && productData.attributes.length > 0 ? productData.attributes.split(',').map(x => ({id: x, name: x})) : []
      })
    }

  }, [productData])

  return (
    <form onSubmit={submitHandler}>
      <fieldset disabled={!update}>
        { errorArray && errorArray.length ? errorArray.map((item, index) => (
            <ModalError key={index} error={item} className={isWarning ? 'warning' : ''} setError={setErrorArray} />
        )) : null }

        { errorsList && Object.keys(errorsList).length > 0 && (
          <ModalError error={errorsList} isArray={true} className={isWarning ? 'warning' : ''} setError={() => setErrorsList([])} />
        )}

        <div className="row row-cols-4">
          <div className="col-md-3">
            <label htmlFor="manufacturer_id">
              Manufacturer <span style={{ color: 'red' }}>*</span>
            </label>
            <Select
              name="manufacturer_id"
              options={convertDataToOptions(selectData.manufacturers)}
              defaultValue={createDefaultOption(productData.manufacturer)}
              onChange={changeSelectHandler}
              isDisabled={!update || isArchived}
            />
            <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0 }}
              required={!formData.manufacturer_id}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="brand_id">
              Brand <span style={{ color: 'red' }}>*</span>
            </label>
            {loading ? (
              <div className="spinner" style={{ width: '30px', height: '30px' }} />
            ) : (
              <Select
                name="brand_id"
                options={convertDataToOptions(brands)}
                defaultValue={createDefaultOption(brands.find(i => i.id === formData.brand_id))}
                onChange={changeSelectHandler}
                isClearable
                isDisabled={!update || isArchived}
              />
            )}
            <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={!formData.brand_id} />
          </div>
          <div className="col-md-3">
            <label htmlFor="status">Status <span style={{ color: 'red' }}>*</span></label>
            <Select
              name="status"
              options={convertDataToOptions(PRODUCT_STATUS_OPTIONS_SHOWN)}
              value={
                formData.status !== undefined &&
                createDefaultOption(PRODUCT_STATUS_OPTIONS.find(i => i.id === formData.status))
              }
              onChange={changeSelectHandler}
              isClearable
              isDisabled={
                !update || (productData.manufacturer && productData.manufacturer.status === 0)
              }
              isOptionDisabled={(option) => option.label === 'Active incomplete'}
            />
            <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={formData.status === null} />

          </div>
          <div className="col-md-3">
            <label htmlFor="product_type_id">Product type {isEditMode && <span style={{ color: 'red' }}>*</span>}</label>
            <Select
              name="product_type_id"
              options={convertDataToOptions(selectData.productTypes)}
              defaultValue={createDefaultOption(productData.type)}
              onChange={changeSelectHandler}
              isClearable
              isDisabled={!update || isArchived}
            />
            <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={!formData.product_type_id} />

          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="product_name">
                Product name <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                id="product_name"
                className={`form-control ${errorsList && errorsList['product_name'] ? 'invalid' : ''}`}
                required
                defaultValue={formData.product_name}
                onChange={changeFieldHandler}
                disabled={isArchived}
              />
              { showError('product_name') }
            </div>
          </div>
          { formData.product_type_id && +formData.product_type_id === 8 && attributes && (
            <div className="col-md-3">
              <label htmlFor="product_type_id">Product attributes {isEditMode && <span style={{ color: 'red' }}>*</span>}</label>
              <Select
                name="attributes"
                options={convertDataAttributesToOptions(attributes)}
                value={formData.attributes.map(x => createDefaultOption(x))}
                onChange={changeMultiSelectHandler}
                isClearable
                isMulti
                isDisabled={!update || isArchived}
              />
              <input
                    type="hidden"
                    id="attributes"
                    className="form-control"
                    required
                    value={formData.attributes}
              />
            </div>
          )}
          { formData.product_type_id && +formData.product_type_id !== 8 && (
            <>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="product_details">
                    Product details
                  </label>
                  <input
                    type="text"
                    id="product_details"
                    className={`form-control ${errorsList && errorsList['product_details'] ? 'invalid' : ''}`}
                    required
                    defaultValue={formData.product_details}
                    onChange={changeFieldHandler}
                    disabled={isArchived}
                  />
                  { showError('product_details') }

                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="product_contents">Product contents</label>
                  <input
                    type="text"
                    id="product_contents"
                    className={`form-control ${errorsList && errorsList['product_contents'] ? 'invalid' : ''}`}
                    defaultValue={formData.product_contents}
                    onChange={changeFieldHandler}
                    disabled={isArchived}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="packaging_unit_id">
                  Packaging unit {isEditMode && <span style={{ color: 'red' }}>*</span>}
                </label>
                <Select
                  name="packaging_unit_id"
                  options={convertDataToOptions(selectData.packagingUnits)}
                  defaultValue={createDefaultOption(productData.packagingUnit)}
                  onChange={changeSelectHandler}
                  isDisabled={!update || isArchived}
                  className={errorsList && errorsList['packaging_unit_id'] ? 'invalid' : ''}
                />
                <input
                  tabIndex={-1}
                  style={{ opacity: 0, height: 0 }}
                  required={!formData.packaging_unit_id}
                />
                { showError('packaging_unit_id') }

              </div>
              <div className="col-md-3">
                  <label htmlFor="gtin">GTIN/EAN {+formData.product_type_id === 1 && !formData.pzn && productData?.productLogistic?.article_packaging_type_id != 2 && isEditMode && (<span style={{ color: 'red' }}>*</span>)}</label>
                  <input
                    type="text"
                    id="gtin"
                    className={`form-control ${errorsList && errorsList['gtin'] ? 'invalid' : ''} `}
                    defaultValue={formData.gtin}
                    onChange={changeFieldHandler}
                    disabled={isArchived}
                    required={+formData.product_type_id === 1 && !formData.pzn && productData?.productLogistic?.article_packaging_type_id != 2}
                  />
                  { showError('gtin') }
              </div>
              <div className="col-md-3">
                  <label htmlFor="manufacturer_product_id">Manufacturer Part Number (MPN)</label>
                  <input
                    type="text"
                    id="manufacturer_product_id"
                    className="form-control"
                    defaultValue={formData.manufacturer_product_id}
                    onChange={changeFieldHandler}
                    disabled={isArchived}
                  />
              </div>
              <div className="col-md-3">
                  <label htmlFor="base_content_volume">
                    Base content volume
                    {formData.base_unit && +formData.base_unit !== 11 && isEditMode && (
                      <span style={{ color: 'red' }}>*</span>
                    )}
                  </label>
                  <input
                    type="number"
                    step="any"
                    id="base_content_volume"
                    className={`form-control ${errorsList && errorsList['base_content_volume'] ? 'invalid' : ''}`}
                    defaultValue={formData.base_content_volume}
                    onChange={changeFieldHandler}
                    disabled={isArchived}
                    required={formData.base_unit && +formData.base_unit !== 11}
                  />
                  { showError('base_content_volume') }
              </div>
              <div className="col-md-3">
                  <label htmlFor="base_unit">Base unit {isEditMode && <span style={{ color: 'red' }}>*</span>}</label>
                  <Select
                    name="base_unit"
                    options={convertDataToOptions(selectData.baseUnits)}
                    defaultValue={createDefaultOption(
                      selectData.baseUnits.find(i => i.id === formData.base_unit)
                    )}
                    className={errorsList && errorsList['base_unit'] ? 'invalid' : ''}
                    onChange={changeSelectHandler}
                    isDisabled={isArchived}
                  />
                  <input
                    tabIndex={-1}
                    style={{ opacity: 0, height: 0 }}
                    required={formData.status == 1? !formData.base_unit : false}
                  />
                  { showError('base_unit') }

              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="pzn">PZN</label>
                  <input
                    type="text"
                    id="pzn"
                    className={`form-control ${errorsList && errorsList['pzn'] ? 'invalid' : ''}`}
                    value={formData.pzn}
                    onChange={changeFieldHandler}
                    disabled={isArchived}
                    required={productData.product_category_pharmacy?.length && formData.status == 1 && productData?.productLogistic?.article_packaging_type_id != 2}
                  />
                  { showError('pzn') }
                  
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="seasonality" >Seasonality</label>
                <Select
                  name="seasonality"
                  options={convertDataToOptions(SEASONALITY)}
                  value={createDefaultOption(SEASONALITY.find(i => i.id === formData.seasonality))}
                  onChange={changeSelectHandler}
                  isDisabled={
                    !update || (productData.manufacturer && productData.manufacturer.status === 0)
                  }
                />
                <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={formData.status === null} />
              </div>
               <div className="col-md-3">
                  <label htmlFor="seasonality">Biocide Registration Number</label>
                    <input
                      id="biozid"
                      className="form-control"
                      type="text"
                      value={formData.biozid}
                      onChange={changeFieldHandler}
                      disabled={isArchived}
                    />
                  </div>
                   <div className="col-md-3">
                    <label htmlFor="seasonality" className='tooltipHeading'>
                      Contents / Ingredients
                      <div className="u_infoButton">
                        <img src="/assets/images/info-button.png" alt="" />
                        
                        <div className="infoBox">
                            <span>Select the products contents (= Inhaltsstoffe)</span>
                        </div>
                      </div> 
                    </label>
                    <input
                      id="inhaltsstoffe"
                      className={`form-control ${errorsList && errorsList['inhaltsstoffe'] ? 'invalid' : ''}`}
                      type="text"
                      value={formData.inhaltsstoffe}
                      onChange={changeFieldHandler}
                      required={productData.product_category_food?.length > 0 || formData.pzn }
                      disabled={isArchived}
                    />
                  { showError('inhaltsstoffe') }

                  </div>
                   <div className="col-md-3">
                      <label htmlFor="seasonality" className="tooltipHeading">
                        Allergens
                        <div className="u_infoButton">
                          <img src="/assets/images/info-button.png" alt="" />
                          
                          <div className="infoBox">
                              <span>Select the products allergens (= Allergene)</span>
                          </div>
                        </div>  
                      </label>
                    <input
                      id="allergene"
                      className="form-control"
                      type="text"
                      value={formData.allergene}
                      onChange={changeFieldHandler}
                      disabled={isArchived}
                    />
                  </div>
                  
                  <div className="col-md-3">
                    <label htmlFor="seasonality" >Lot number {isEditMode && <span style={{ color: 'red' }}>*</span>}</label>
                    <Select
                        id="lot_number_required"
                        name="lot_number_required"
                        options={options}
                        defaultValue={options.find((x) => x.value === formData.lot_number_required)}
                        onChange={changeSelectHandler}
                        isDisabled={isArchived}
                      />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="seasonality" >Expiration Date {isEditMode && <span style={{ color: 'red' }}>*</span>}</label>
                    <Select
                        id="expiration_date"
                        name="expiration_date"
                        options={options}
                        defaultValue={options.find((x) => x.value === formData.expiration_date)}
                        onChange={changeSelectHandler}
                        isDisabled={isArchived}
                      />
                  </div>
            </>
        )}
        </div>

        {!isEditMode && (

          <div className="sticky_buttons_wrapper">
              {createProductLoading ? (
                  <div className="spinner">Spinner</div>
              ) : (
                <>
                <button className="btn btn-primary px-3 mx-2" onClick={submitHandler}>
                  Save
                </button>
                <button className="btn btn-secondary px-3 mx-2">
                  Discard
                </button>
                </>
              )}

            </div>
        )}

      </fieldset>
    </form>
  );
};

export default withRouter(ProductInformationForm);
