import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';


import { ImportDocumentUpload } from '../../services/api/mutationService';
import { DOCUMENTS_UPLOAD_TEMPLATES, DOCUMENTS_UPLOAD_TYPES, PRODUCT_IMPORT_OPTIONS, PRODUCT_IMPORT_TEMPLATES, UPLOAD_DOCS_OPTIONS } from '../../lib/constants/fields';
import ModalError from '../../components/modal/ModalError';
import { BlobServiceClient } from "@azure/storage-blob";
// import './ImportModal.scss';

const DocumentUploadModal = ({ setModalOpen }) => {
  const [fileName, setFileName] = useState('No file selected');
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const filePickerRef = useRef();
  const history = useHistory();
  const [DocumentUploadMutator, { loading, error: importError }] = useMutation(ImportDocumentUpload);
  const [formData, setFormData] = useState({
    type: DOCUMENTS_UPLOAD_TYPES[0].value,
    file: null
  });

  const dispatch = useDispatch();


  useEffect(() => {
    if (!file || (!file.name.includes('xlsx') && !file.name.includes('csv'))) {
      return;
    }
    setFileName(file.name.replace(' ', '_'));
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      setFormData({
        ...formData,
        file: fileReader.result
      });
    };
    return () => {
      fileReader = null;
    };
    // eslint-disable-next-line
  }, [file]);

  const backdropClickHandler = event => {
    if (event.target.id === 'importModal') {
      setModalOpen(false);
    }
  };

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const changeSelectHandler = (option, data) => {
    setFormData({
      ...formData,
      [data.name]: option.value
    });
    setError(null);
  };

  const selectClickHandler = () => {
    filePickerRef.current.click();
  };

  const selectFileHandler = async event => {
    const { files } = event.target;
    let selectedFile;
    if (files && files.length === 1) {
      selectedFile = files[0];

      setFile(selectedFile);
    }
    setError(null);
  };

  const downloadClickHandler = () => {
    window.location = DOCUMENTS_UPLOAD_TEMPLATES[formData.type];
  };

  async function upload(file) {
    const blobServiceClient = new BlobServiceClient(
      "https://documentsupload.blob.core.windows.net/uploads?sp=racwdli&st=2023-05-29T19:18:34Z&se=2023-05-30T03:18:34Z&skoid=63fa937c-48fa-4910-9fe0-9beb9c935baa&sktid=ea3ed546-17ce-495c-a186-69fad2403d4c&skt=2023-05-29T19:18:34Z&ske=2023-05-30T03:18:34Z&sks=b&skv=2022-11-02&sv=2022-11-02&sr=c&sig=EwDqXMLpL8tvLxdGULlDz2WW%2BUeoUXZvcnyvw7k7hT8%3D"
    );

    console.log(blobServiceClient)

    const containerClient = blobServiceClient.getContainerClient('uploads');

    console.log(containerClient)
    const blobClient = containerClient.getBlobClient(file.name);
    const blockBlobClient = blobClient.getBlockBlobClient();

    console.log(blockBlobClient)

    const result = await blockBlobClient.uploadBrowserData(file, {
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      onProgress: ev => console.log(ev)
    });
    console.log(`Upload of file '${file.name}' completed`);
  }


  const submitHandler = async event => {
    event.preventDefault();

  //  if(fileName.split('_')[0].toLowerCase() != formData.type.split('_')[0].toLowerCase()){
  //   setError(`File naming convention is wrong, it should be starting with "${formData.type.split('_')[0].toLowerCase()}_"`)

  //   return false;
  //  }

   let fn = fileName
   if(formData.type == 'billing_ebazaaris_sira_seller_transactions'){
    fn = fn + '_' + formData.marketplace
   }

    const fd = {...formData}

    delete fd.marketplace

    const { data } = await DocumentUploadMutator({
      variables: { input: {...fd, filename:  fn } }
    });
    const { id, error } = data.importDocumentUpload;

    console.log('ImportDocumentUpload', id)

    if (error) {
      setError(error);
      return;
    }

    if (!id) {
      setError('Import file column structure did not match, please try again');
      return;
    }

    dispatch({
      type: 'UPDATE',
      payload: {
        type: 'success',
        message: 'Recored was successfully updated'
      }
    });

    setModalOpen(false)

    // history.push(`/products/import/${data.importProduct.id}`);
  };

  if (importError) {
    throw new Error(importError);
  }


  return (
    <>
      <div className="modal-backdrop show" />
      <div
        className="modal"
        id="importModal"
        tabIndex="-1"
        style={{ display: 'block' }}
        onMouseDown={backdropClickHandler}
      >
        <div className="modal-dialog import-modal">
          <div className="modal-content">
            <form onSubmit={submitHandler}>
              <div className="modal-header">
                <h4 className="modal-title">Documents upload</h4>
                <button type="button" className="close" onClick={() => setModalOpen(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ModalError error={error} setError={setError} />
                <div className="form-group">
                  <label htmlFor="name">
                    Name of import <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    required
                    defaultValue={formData.name}
                    onChange={changeFieldHandler}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="type">
                    Import type <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Select
                    name="type"
                    options={DOCUMENTS_UPLOAD_TYPES}
                    defaultValue={DOCUMENTS_UPLOAD_TYPES[0]}
                    onChange={changeSelectHandler}
                    styles={customStyles}
                  />
                </div>

                { formData.type == 'billing_ebazaaris_sira_seller_transactions' && (
                  <div className="form-group">
                    <label htmlFor="type">
                      Marketplace country <span style={{ color: 'red' }}>*</span>
                    </label>
                    <Select
                      name="marketplace"
                      options={UPLOAD_DOCS_OPTIONS}
                      onChange={changeSelectHandler}
                      styles={customStyles}
                    />
                    <input
                      tabIndex={-1}
                      style={{ opacity: 0, height: 0 }}
                      required={!formData.marketplace}
                    />
                  </div>
                )}

                <label htmlFor="file">
                  Select import file <span style={{ color: 'red' }}>*</span>
                </label>
                <div className="select-file-wrapper">
                  <p>{fileName}</p>
                  <input
                    ref={filePickerRef}
                    style={{ display: 'none' }}
                    type="file"
                    accept=".csv"
                    onChange={selectFileHandler}
                  />
                  <div className="btn btn-secondary px-4" onClick={selectClickHandler}>
                    Select file
                  </div>
                </div>

                
                <div className="download-wrapper" onClick={downloadClickHandler} >
                    <i className="fas fa-download mr-2" />
                <span>Download xlsx template</span>
                  </div>
              </div>
              <div className="modal-footer justify-content-start">
                {loading ? (
                  <div className="spinner">Spinner</div>
                ) : (
                  <button type="submit" className="btn btn-primary" disabled={!formData.file}>
                    Import
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const customStyles = {
  // For the options
  option: (styles, { isDisabled}) => {
    if(isDisabled){
      return {
        ...styles,
        borderBottom: '1px solid #ccc',
        innerHeight: '1px',
        padding: '0px',
        margin: '0px'
      };
    }

    return { ...styles }
  },
};

export default DocumentUploadModal;
